<template>
  <div>
    <div
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div v-if="drawerOpen" class="z-40 fixed inset-0 transition-opacity">
        <div
          class="absolute inset-0 bg-gray-800 opacity-80"
          tabIndex="{0}"
          @click="drawerTigger"
        />
      </div>
    </div>
    <aside
      v-bind:class="[drawerOpen ? 'translate-x-0' : '-translate-x-full']"
      class="transform top-0 left-0 bg-gradient-to-b from-blue-700 to-blue-900 fixed h-full border-0 overflow-auto ease-in-out transition-all duration-300 z-50"
    >
      <div v-if="isDone" class="mt-10 flex flex-col text-center">
        <img
          :src="userLinePictureUrl"
          width="100"
          alt=""
          class="rounded-full h-24 w-24 mb-5 mx-auto"
        />

        <div class="text-3xl text-white text-normal font-semibold mb-5">
          {{ userLineDisplayName }}
        </div>
      </div>
      <router-link to="/submit-history" @click="drawerTigger">
        <div
          class="flex text-white items-center px-2 py-3 pl-3 border-t border-b border-blue-700"
        >
          <span class="mr-2"
            ><img src="../assets/icons/report.svg" class="aside-icon"
          /></span>
          <span class="mt-1">ประวัติการส่งใบเสร็จ</span>
        </div>
      </router-link>
      <router-link to="/redeem-history" @click="drawerTigger">
        <div
          class="flex text-white items-center px-2 py-3 pl-3 border-t border-b border-blue-700"
        >
          <span class="mr-2"
            ><img src="../assets/icons/report.svg" class="aside-icon"
          /></span>
          <span class="mt-1">ประวัติการแลกของรางวัล</span>
        </div>
      </router-link>
      <router-link to="/condition" @click="drawerTigger">
        <div
          class="flex text-white items-center px-2 py-3 pl-3 border-t border-b border-blue-700 mt-48"
        >
          <span class="mr-2"
            ><img src="../assets/icons/medical-report.svg" class="aside-icon"
          /></span>
          <span class="mt-1">ข้อตกลงและเงื่อนไข</span>
        </div>
      </router-link>
      <div class="mt-3 w-full">
        <div class="items-center px-4 py-2 text-white text-center leading-none">
          <div class="mt-2">สอบถามรายละเอียดเพิ่มเติม</div>
          <div class="my-2 text-3xl text-normal font-bold">02-591-9800</div>
          <div class="mt-2">จ. - ศ. : 9.00 น. - 18.00 น.</div>
        </div>
      </div>
      <div class="text-white mt-0 pb-10 text-center">
        เวอร์ชั่น {{ appVersion }}
      </div>
    </aside>
    <div
      id="footer"
      class="z-40 fixed bg-gradient-to-r from-gray-300 via-white to-gray-600 pt-1 bottom-0 left-0 right-0 w-full flex shadow-lg"
    >
      <div class="bg-blue-800 pt-1 text-white w-full flex px-0">
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/submit">
            <div class="text-center w-full">
              <div class="text-xl hover:text-yellow-500">
                <i class="bi bi-camera"></i>
              </div>

              <div class="text-xs rounded-full pt-1">ส่งใบเสร็จ</div>
            </div>
          </router-link>
        </div>

        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/condition">
            <div class="text-center w-full">
              <div class="text-white text-xl hover:text-yellow-500">
                <i class="bi bi-flag"></i>
              </div>
              <div class="text-xs text-white rounded-full pt-1">กติกา</div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/">
            <div class="text-center w-full -mt-10">
              <div
                class="bg-gray-300 text-blue-800 text-3xl hover:bg-blue-900 w-16 h-16 pt-2 rounded-full border-4 border-white shadow-xl"
              >
                <i class="bi bi-house-door"></i>
              </div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center py-2">
          <router-link to="/rewards">
            <div class="text-center w-full">
              <div class="text-white text-xl hover:text-yellow-500">
                <i class="bi bi-check2-square"></i>
              </div>
              <div class="text-xs text-white rounded-full pt-1">ของรางวัล</div>
            </div>
          </router-link>
        </div>
        <div class="flex flex-grow w-1/5 items-center justify-center">
          <router-link to="/history">
            <div class="text-center w-full">
              <div class="text-white text-xl hover:text-yellow-500">
                <img
                  :src="userLinePictureUrl"
                  alt=""
                  class="rounded-full mx-auto border-2 border-blue-800"
                />
              </div>
              <div class="text-xs text-white rounded-full pt-1">โปรไฟล์</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { version } from "../../package";
import liff from "@line/liff";

export default {
  data() {
    return {
      drawerOpen: false,
      appVersion: version,
      isDone: false,
      userLineDisplayName: "",
      userLinePictureUrl: "",
    };
  },
  mounted() {},
  async created() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const profile = await liff.getProfile();
      console.table(profile);
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;

      this.isDone = true;
    }
  },
  computed: {},
  methods: {
    drawerTigger() {
      // console.log("vue-layout:", this.drawerOpen);
      this.drawerOpen = !this.drawerOpen;
    },
  },
};
</script>
<style scoped>
#footer img {
  width: 30px;
  height: 30px;
}
#footer i {
  font-size: 22px;
}
</style>

<template>
  <div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00257A"
      ></loading>

      <div class="">
        <div class="rounded-lg m-2">
          <div class="text-blue-700 font-bold text-2xl mb-3 text-center">
            {{ header }}
          </div>
          <div v-html="content" class="text-sm"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "../service/service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Condition",
  data() {
    return {
      header: null,
      content: null,
      isLoading: true,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  mounted() {
    this.isLoading = true;
    this.service.getContent("CONDITION").then((data) => {
      this.header = data.contentData.PC_HEADER;
      this.content = data.contentData.PC_BODY;
      this.isLoading = false;
    });
  },
};
</script>

<template>
  <div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00257A"
      ></loading>

      <div class="rounded-lg m-2">
        <div class="text-blue-700 font-bold text-2xl mb-3 text-center">
          ส่งใบเสร็จ
        </div>

        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <label class="font-bold mb-2 mt-3"
            >ร้านค้าหรือห้างที่ซื้อสินค้า</label
          >

          <select
            v-model="inputShopName"
            placeholder="กรุณาเลือกร้าน"
            class="px-3 py-3 relative border-0 w-full bg-white border border-blue-900 rounded-lg"
          >
            <option value="">กรุณาเลือกร้าน</option>
            <option
              v-for="(s, index) in shopList"
              :key="index"
              :value="s.SH_NAME"
            >
              {{ s.SH_NAME }}
            </option>
          </select>
        </div>
        <div class="text-gray-700 font-bold my-2">เลขที่ใบเสร็จ</div>
        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <input
            v-model="submitBillNo"
            type="text"
            placeholder="เลขที่ใบเสร็จ"
            class="px-3 py-3 relative border-0 w-full bg-white border border-blue-900 rounded-lg"
          />
        </div>
        <!--
          <div class="text-right text-sm my-3">
          ดูตัวอย่างเลขที่ใบเสร็จได้
          <span class="text-blue-700 underline" @click="showModal = true"
            >ที่นี่</span
          >
        </div> -->
        <!-- -->
        <!-- <div class="text-gray-700 font-bold mb-2">วัันที่ซื้อ</div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <span
              class="z-10 h-full absolute text-center text-blue-700 absolute bg-transparent rounded items-center justify-center w-8 pl-3 py-3"
            >
              <CalendarIcon class="ml-1 h-6 w-6" />
            </span>
            <input
              v-model="submitBuyDate"
              type="date"
              placeholder="วันที่ซื้อ"
              class="px-3 py-3 relative bg-white bg-white rounded-full border border-gray-300 outline-none focus:outline-none focus:ring w-full pl-12"
            />
          </div> -->
        <!-- -->

        <div class="text-gray-700 font-bold my-2">จำนวนเงิน</div>
        <!-- <div class="text-left text-sm mb-3 text-red-700">
            * มูลค่าเฉพาะสินค้าที่ร่วมรายการเท่านั้น
          </div> -->
        <div class="relative flex w-full flex-wrap items-stretch mb-3">
          <input
            v-model="submitBuyAmount"
            type="tel"
            placeholder="จำนวนเงิน"
            class="px-3 py-3 relative border-0 w-full bg-white border border-blue-900 rounded-lg"
          />
        </div>
        <div class="text-gray-700 font-bold mb-2">อัพโหลดใบเสร็จ</div>
        <div
          class="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md"
        >
          <div class="space-1 p-2 text-center">
            <div class="flex text-sm text-gray-600">
              <label
                for="file-upload"
                class="relative cursor-pointer rounded-md font-medium"
              >
                <div class="w-full py-5" v-if="submitPicUrl == null">
                  <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div v-if="submitPicUrl == null">
                    อัพโหลดหรือถ่ายภาพใบเสร็จ
                  </div>
                </div>
                <div v-if="submitPicUrl" class="text-center">
                  <img :src="submitPicUrl" style="width: 100%" />
                  <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                  <div class="mt-3 text-blue-700">กดที่รูปเพื่อเปลี่ยนภาพ</div>
                </div>

                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  @change="onFileChange"
                />
              </label>
            </div>
            <p class="text-xs text-gray-500"></p>
          </div>
        </div>
      </div>

      <!-- -->

      <div class="flex flex-wrap mx-auto mt-5">
        <button
          @click="goSubmit"
          :disabled="btnDisable"
          class="w-full text-xl font-bold rounded px-3 py-2 mr-1 rounded-lg border-b-4 border-l-2 shadow-lg border-blue-800 bg-gradient-to-r from-blue-800 to-blue-700 text-white"
        >
          ยืนยันการส่งใบเสร็จ
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
ul,
li {
  list-style: none;
  margin-left: 0 !important;
}
.max-height-48 {
  max-height: 200px;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
import configs from "../config";
export default {
  name: "Submit",
  data() {
    return {
      userLineId: null,
      userIsRegister: false,
      isLoading: true,
      fullPage: true,
      submitBuyDate: null,
      submitBuyAmount: "",
      submitBuyShop: null,
      submitBuyShopId: 0,
      submitBuyShopSub: null,
      submitBillNo: null,
      submitPic: null,
      submitPicUrl: null,
      submitPicName: null,
      showModal: false,
      shopList: null,
      shopListChilds: null,
      showShops: false,
      configs,
      userCoordinates: null,
      btnDisable: false,
      inputShopName: "",
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    let today = new Date();
    let tomonth = today.getMonth() + 1;
    let month = tomonth < 10 ? "0" + tomonth : tomonth;
    let todate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    this.service = new Service();
    this.submitBuyDate = today.getFullYear() + "-" + month + "-" + todate;

    this.$getLocation()
      .then((coordinates) => {
        this.userCoordinates = coordinates;
        // console.log(coordinates);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.service.getCampaignInfo().then((data) => {
        if (data.campaignInfo.C_ONLINE !== 1) {
          router.push("/off");
        } else {
          this.service.getCustomerInfo(this.userLineId).then((data) => {
            console.log(data);
            if (data.isRegisted === 1) {
              // console.log(data.custInfo);
              this.service.getShopName().then((data) => {
                this.shopList = data.shopData;
              });
            } else {
              this.$swal
                .fire({
                  html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "ไปลงทะเบียน  &rarr;",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    router.push("/register");
                  }
                });
            }
            this.isLoading = false;
          });
        }
      });
    }
  },
  methods: {
    onFileChange(e) {
      this.submitPic = e.target.files[0];
      this.submitPicName = e.target.files[0].name;
      this.submitPicUrl = URL.createObjectURL(this.submitPic);
    },

    async goSubmit() {
      let isError = false;
      let txtError = "";
      this.btnDisable = true;
      if (
        this.inputShopName === null ||
        this.inputShopName == 0 ||
        this.inputShopName === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุร้านค้าหรือห้างที่ซื้อสินค้า</li>";
      }

      if (this.submitBillNo === null || this.submitBillNo === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเลขที่ใบเสร็จ</li>";
      }
      // if (this.submitBuyDate === null || this.submitBillNo === "") {
      //   isError = true;
      //   txtError += "<li>กรุณาระบุวันที่ซื้อ</li>";
      // }
      if (
        this.submitBuyAmount === null ||
        this.submitBuyAmount === "" ||
        this.submitBuyAmount == 0
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุจำนวนเงินที่ซื้อ</li>";
      }
      // else {
      //   console.log(this.submitBuyAmount);
      //   console.log(parseInt(this.minBill));
      //   if (this.submitBuyAmount < parseInt(this.minBill)) {
      //     isError = true;
      //     txtError +=
      //       "<li>ใบเสร็จมียอดการซื้อต่ำกว่าเงื่อนไขที่สามารถร่วมกิจกรรมได้</li>";
      //   }
      // }
      if (this.submitPic === null || this.submitPic === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดใบเสร็จ</li>";
      }
      if (!isError) {
        // upload image first
        let imgFormData = new FormData();
        this.isLoading = true;
        imgFormData.append("image", this.submitPic);
        await this.service
          .uploadBill(imgFormData)
          .then((data) => {
            console.log(data);
            if (data.isSuccess === 1) {
              let formData = new FormData();
              formData.append("line_id", this.userLineId);
              formData.append("bill_pic", data.uploadResult);

              formData.append("bill_no", this.submitBillNo);
              formData.append("bill_amount", this.submitBuyAmount);

              // formData.append("bill_shop", "");
              formData.append("bill_shop", this.inputShopName);
              if (this.userCoordinates) {
                formData.append(
                  "bill_geolocation",
                  this.userCoordinates.lat + "," + this.userCoordinates.lng
                );
              } else {
                formData.append("bill_geolocation", "0,0");
              }

              //formData.append("submitBuyShopSubId", this.submitBuyShopSub);
              this.service
                .submitBill(formData)
                .then((rez) => {
                  this.isLoading = false;
                  if (rez.data.isSuccess === 1) {
                    router.push("/success");
                  } else {
                    this.btnDisable = false;
                    this.$swal({
                      html: rez.data.msgError,
                      title: "พบข้อผิดพลาด",
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonText: "ตกลง",
                    });
                  }
                })
                .catch((error) => console.error("member-submit-bill", error));
            } else {
              this.$swal({
                html: "<ul>" + data.uploadResult + "</ul>",
                title: "พบข้อผิดพลาด",
                icon: "error",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: "#b91c1c",
                cancelButtonText: "ตกลง",
              });
              this.isLoading = false;
              this.btnDisable = false;
            }
          })
          .catch((error) => console.error("s3-upload", error));
        this.isLoading = false;
        // submit to backend
      } else {
        this.btnDisable = false;
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
